<template>
  <div class="dswindow">
    <div class="securitybox">
      <div class="btn"></div>
      <div class="securitymsg">
        <div class="securityleft">
          <div>设备名称:{{deviceName}}</div>
          <div>设备状态:{{runStatus}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAccessControlStatus } from '@/api'
export default {
  data() {
    return {
      loading: false,
      deviceName:'',
      runStatus:''
    }
  },
  mounted() {
    this.$emit('loading')
    if(this.$route.query.deviceCode){
      this.getDevice(this.$route.query.deviceCode)
      // this.getDevice('1001999$1$0$0')
    }else{
      this.deviceName = '未获取到设备'
    }
  },
  methods:{
    async getDevice(code){
      const form = {deviceNumber:code}
      console.log(form);
      let {data:res} = await getAccessControlStatus(form)
      this.deviceName = res.deviceName
      this.runStatus = res.runStatus
    }
  }
}
</script>

<style lang="scss" scoped>
.dswindow {
    width: 698px;
    height: 402px;
    padding-top: 20px;
  .securitybox {
    position: relative;
    width: 400px;
    height:140px;
    background: url('~@/assets/gd/ds_watercard.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 22px 23px;
    font-size: 20px;
    color: #fff;
    .btn{
      background: url('~@/assets/gd/ds_closebtn.png') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: -19px;
      top: -20px;
      width: 38px;
      height: 44px;
    }
    p {
      text-align: center;
    }
    .securitymsg {
      display: flex;
      width: 100%;
      height: 95%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 25px 30px 0 30px;
      font-size: 18px;
      .securityleft {
        width:100%;
        height: 30px;
        div {
          margin-bottom: 15px;
        }
        span {
          color: #3aa1ff;
          margin-left: 5px;
        }
      }
      .securityright {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #e9e9e9;
        > p {
          position: absolute;
          width: 100%;
          top: 50%;
          transform: translate(0, -50%);
          color: #333333;
          font-size: 34px;
        }
      }
    }
  }

}
</style>